type EnvironmentTypes = 'development' | 'staging' | 'production' | 'test' | 'unknown';

interface PublicEnvironmentVariables {
  APP_VERSION: string;
  ENV: EnvironmentTypes;
  GOOGLE_API_KEY: string;
  SENTRY_DSN: string;
  SENTRY_ENABLED: boolean;
}

export default class PublicEnvironment {
  static #instance: PublicEnvironment;
  static key: '__public_env';

  env: PublicEnvironmentVariables = {
    APP_VERSION: 'latest',
    ENV: 'unknown',
    GOOGLE_API_KEY: 'nokey',
    SENTRY_DSN: '',
    SENTRY_ENABLED: false,
  };

  constructor() {
    if (typeof window !== 'undefined' && PublicEnvironment.getScriptEl()) {
      const rawJSON = PublicEnvironment.getScriptEl()?.textContent;
      if (rawJSON) {
        Object.assign(this.env, JSON.parse(rawJSON));
      }
    } else {
      this.env.APP_VERSION = process.env.APP_VERSION || 'latest';
      this.env.ENV = this.#getEnv();
      this.env.GOOGLE_API_KEY = process.env.GOOGLE_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '';
      this.env.SENTRY_DSN = process.env.SENTRY_DSN || '';
      this.env.SENTRY_ENABLED = process.env.SENTRY_ENABLED === 'true';
    }
  }

  static get<T extends keyof PublicEnvironmentVariables>(key: T): PublicEnvironmentVariables[T] {
    return PublicEnvironment.getInstance().env[key];
  }

  static getInstance() {
    if (!PublicEnvironment.#instance) {
      PublicEnvironment.#instance = new PublicEnvironment();
    }
    return PublicEnvironment.#instance;
  }

  static getScriptEl() {
    return document.getElementById('__public_env');
  }

  #getEnv() {
    if (process.env.NODE_ENV === 'test') {
      return 'test';
    }
    const maybeEnv = process.env.ENV;
    if (!maybeEnv || !['development', 'staging', 'production'].includes(maybeEnv)) {
      return 'unknown' as const;
    }
    return maybeEnv as EnvironmentTypes;
  }
}
