globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"2892dd65a37b0744ffd0c14c3b6f1483535fe004"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

import PublicEnvironment from '@/common/lib/PublicEnvironment.ts';

if (PublicEnvironment.get('SENTRY_ENABLED')) {
  Sentry.init({
    environment: PublicEnvironment.get('ENV'),
    dsn: PublicEnvironment.get('SENTRY_DSN'),
    release: PublicEnvironment.get('APP_VERSION'),
    tracesSampleRate: 0.01,
    ignoreErrors: [
      /Blocked a frame with origin/,
      /LCM/,
      /Script error/,
      /Failed to register a ServiceWorker/,
      /sw.js/,
      /NetworkError/,
      'document.getElementsByClassName.ToString is not a function',
      /Script load failed/,
      /International roaming is currently off/,
      /NotAllowedError/,
      '<unknown>',
      'undefined',
      "ReferenceError: Can't find variable: DC",
      'ReferenceError: DC is not defined',
      "ReferenceError: Can't find variable: YAHOO",
      'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:*',
      // External plugin errors below
      `Unexpected identifier 'UA'. Expected ')' to end an argument list.`,
      'missing ) after argument list.',
      'Invalid value for ESTIMATE_MODIFIER - must be between 0 and 2',
      /Cannot read properties of null (reading 'src')/,
      /Failed to fetch/,
      /Load failed$/,
      /ResizeObserver/,
      `Can't find variable: ResizeObserver`,
      'ImpelAna',
      'SecurityError',
      'Unexpected token',
      `Failed to execute 'insertBefore'`,
      'The string did not match the expected pattern.',
      'The object can not be found here.',
      'Unexpected identifier',
      `Cannot read properties of null (reading 'removeChild')`,
      `null is not an object (evaluating 'm.parentNode.removeChild')`,
      /fetch failed$/,
      `Non-Error promise rejection captured with value: Timeout`,
      'cancelled',
      `m.parentNode is null`,
      'IndexSizeError',
      /Non-Error promise rejection captured with value: You don't have permissions/,
      /AbortError: The operation was aborted/,
      /'defineProperty' on proxy: trap returned falsish for property 'request'/,
      /Can't find variable: _AutofillCallbackHandler/,
    ],
    denyUrls: [
      /gtm-msr\.appspot\.com/,
      /a\.adroll\.com/,
      /trkcall\.com/,
      /script\.hotjar\.com/,
      /formobile\.com\.au/,
      /script\.hotjar\.com/,
      /maps\.googleapis\.com/,
      /google-analytics\.com/,
      /o\.clarity\.ms/,
      /regioner\.impel\.io/,
      /clarity\.ms/,
    ],
    initialScope: {
      tags: { client: true, component: 'listing-portal' },
    },
    integrations: [Sentry.extraErrorDataIntegration()],
  });

  process.on('unhandledRejection', (err) => {
    Sentry.captureException({ message: 'error coming from _app', error: err });
  });

  process.on('uncaughtException', (err) => {
    Sentry.captureException({ message: 'error coming from _app', error: err });
  });
}
